$primary: #4E1C55;

$success: #16a34a;
$danger: #dc2626;

@import "~bootstrap/scss/bootstrap";

body {
  background-color: #e5e7eb;
}

.bg-img-start {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
}

.shape-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.shape {
  position: absolute;
  z-index: -1;
}

.shape-bottom>svg {
  width: 100%;
  height: auto;
  margin-bottom: -1px;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

#profile-nav-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uppercase {
  text-transform: uppercase;
}

.plan-row {
  transition: background-color .2s;
  &:hover {
    background-color: $light;
    cursor: pointer;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.large-table {
  th,
  td {
    padding: 1rem 0.5rem;
  }

  tbody tr:last-of-type {
    border-bottom: 1px solid white;
  }
}